import moment from 'moment'

export default {
  getSocialSecurityContributions: (state) => state.socialSecurityContributions,
  getSocialSecurityContribution: (state) => (id) => {
    return state.socialSecurityContributions.find(socialSecurityContribution => socialSecurityContribution.id === parseInt(id))
  },
  getSocialSecurityContributionsContext: (state) => state.socialSecurityContributionsContext,
  getEmptySocialSecurityContribution: (state) => JSON.parse(JSON.stringify(state.emptySocialSecurityContribution)),
  socialSecurityContributionNotCompletelyPaid: (state) => {
    let scc = state.socialSecurityContributions.filter(socialSecurityContribution => socialSecurityContribution._paymentStatus != 'Paid')

    scc.sort((a, b) => {return new Date(b.month) - new Date(a.month)})

    return scc
  },
  getSocialSecurityContributionBetweenDates: (state) => (dates) => {
    return state.socialSecurityContributions.filter(s => dates.start <= moment(s.month) && moment(s.month) <= dates.end)
  }
}