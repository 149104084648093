import moment from 'moment'

const startDate = moment().minutes(0)
const endDate = moment().minutes(30)

export default {
  events: [],
  emptyEvent: {
    'isAllDay': false,
    'isTripWithPersonalVehicle': false,
    'type': 'default',
    'isValidated': null,
    'mileageAllowanceCost': 0,
    'startDate': startDate.toISOString(),
    'startPlace': {},
    'endDate': endDate.toISOString(),
    'endPlace': {},
    'documents': [],
    'steps': []
  },
  params: {
    entity: [
      // 'startPlace',
      // 'endPlace',
      'phase',
      'workflow',
      'documents',
      'additionalMembers'
    ],
    collection: [
      'place',
      'startPlace',
      'endPlace',
      'steps'
    ]
  }
}