export default {
  outgoingInvoices: [],
  emptyOutgoingInvoice: {
    billingDate: (new Date()).toISOString().slice(0, 10),
    paymentMethod: 1,
    paymentTerm: 3,
    products: [],
    isDiscountPercent: false,
    preTaxAmount: 0,
    vat: 0,
    totalAmount: 0,
  },
  params: {
    entity: [
      'article',
      'bankOperations',
      'billingAddress',
      'billingCompany',
      'customerCompany',
      'customerIndividual',
      'deliveryAddress',
      'parent',
      'paymentTerm',
      'paymentMethod',
      'phase',
      'vatRate',
      'workflow',
      'customerPurchaseOrder',
      'offer',
      'bankAccount',
      'incomingInvoices'
    ],
    collection: [
      'products',
    ]
  }
}