import i18n from '../../libs/i18n'

const translateTerms = (autocompleteDocumentCategory) => {
  autocompleteDocumentCategory.name = i18n.t(autocompleteDocumentCategory.name)
}

export default {
  SET_AUTOCOMPLETEDOCUMENTCATEGORIES (state, autocompleteDocumentCategories) {
    autocompleteDocumentCategories.forEach(dc => translateTerms(dc))
    state.autocompleteDocumentCategories = autocompleteDocumentCategories
  },
}