import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '@/store'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'

export default {
  getBankAccounts ({ commit }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/bankAccounts'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          commit('SET_BANKACCOUNTS', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit
            recursiveCall(url, 'bankAccount', limit, offset, commit, 1).then(() => {
              store.commit('app/TOGGLE_LOADING', false)
              resolve(true)
            })
          } else {
            store.commit('app/TOGGLE_LOADING', false)
            resolve(true)
          }
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addBankAccount ({ commit, state }, bankAccount) {
    return await cleanEntityToSend(bankAccount, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/bankAccounts', clonedEntityToSend)
            .then(response => {
              commit('ADD_BANKACCOUNT', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The bankAccount has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateBankAccount ({ commit, state }, bankAccount) {
    return await cleanEntityToSend(bankAccount, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/bankAccounts/' + bankAccount.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_BANKACCOUNT', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The bankAccount has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeBankAccount ({ commit }, bankAccount) {
    return new Promise((resolve, reject) => {
      api.delete('/bankAccounts/' + bankAccount.id)
        .then(response => {
          commit('REMOVE_BANKACCOUNT', bankAccount)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The bankAccount has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },
  getAutocompleteBankAccounts ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/autocomplete/bankAccounts'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_AUTOCOMPLETEBANKACCOUNTS', response.results)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })

  },

}