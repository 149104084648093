import { sortCompare } from '../../utils/utils'

export default {
  getAutocompleteBankAccounts: (state) => state.autocompleteBankAccounts,
  getBankAccounts: (state) => state.bankAccounts,
  getBankAccount: (state) => (id) => {
    return state.bankAccounts.find(bankAccount => bankAccount.id === parseInt(id))
  },
  getEmptyBankAccount: (state) => JSON.parse(JSON.stringify(state.emptyBankAccount)),
  getBanks: (state) => state.banks.sort((a, b) => a.title == b.title ? 0 : (a.title < b.title ? -1 : 1))
}