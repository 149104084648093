export default {
  UPDATE_WINDOW_WIDTH(state, val) {
    state.windowWidth = val
  },
  UPDATE_MENU_COLLAPSED(state, val) {
    state.isVerticalMenuCollapsed = val
  },
  UPDATE_NAV_MENU_HIDDEN(state, val) {
    state.isNavMenuHidden = val
  },
  TOGGLE_OVERLAY(state, val) {
    state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
  },
  TOGGLE_LOADING(state, val) {
    state.isLoading = val
  },
  ADD_LOADER(state) {
    state.loader++
  },
  REMOVE_LOADER(state) {
    state.loader--
  },
}