import i18n from '../../libs/i18n'

export default {
  SET_ARTICLES (state, articles) {
    articles.forEach(a => {
      if (a.id == 784) {
        a.name = i18n.t(a.name)
      }
    })

    state.articles = articles
  },
  ADD_ARTICLE (state, article) {
    state.articles.push(article)
  },
  UPDATE_ARTICLE(state, article) {
    let index = state.articles.findIndex(i => i.id === article.id)
    state.articles.splice(index, 1, article)
  },
  REMOVE_ARTICLE (state, article) {
    state.articles = state.articles.filter(item => item.id != article.id)

    // let index = state.articles.findIndex(i => i.id === article.id)
    // state.articles.splice(index, 1)
  },
}