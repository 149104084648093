export default {
  SET_AUTOCOMPLETECONTACTS (state, autocompleteContacts) {
    state.autocompleteContacts = autocompleteContacts
  },
  // Payload : contact, type(company/user)
  ADD_AUTOCOMPLETECONTACT (state, payload) {
    let lastUuid=state.autocompleteContacts.at(-1)._uuid
    state.autocompleteContacts.push({
      id: payload.contact.id,
      _display: payload.contact._display,
      _type: payload.type,
      _uuid: ++lastUuid,
    })
  },
  // Payload : contact, type(company/user)
  UPDATE_AUTOCOMPLETECONTACT (state, payload) {
    console.log(payload)
    let index = state.autocompleteContacts.findIndex(i => i.id === payload.contact.id && i._type == payload.type)
    state.autocompleteContacts[index]._display = payload.contact._display
  },
}