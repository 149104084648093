import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'

export default {
  getArticles ({ commit, state }, payload) {
    // store.commit('app/ADD_LOADER')

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/articles'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          commit('SET_ARTICLES', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit
            recursiveCall(url, 'article', limit, offset, state.articles, commit, 1).then(() => {
              // store.commit('app/REMOVE_LOADER')
              resolve(true)
            })
          } else {
            // store.commit('app/REMOVE_LOADER')
            resolve(true)
          }

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addArticle ({ commit, state }, article) {
    return await cleanEntityToSend(article, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/articles', clonedEntityToSend)
            .then(response => {
              commit('ADD_ARTICLE', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The article has been ', {crud: i18n.t('created')}),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateArticle ({ commit, state }, article) {
    return await cleanEntityToSend(article, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/articles/' + article.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_ARTICLE', response.data)
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The article has been ', {crud: i18n.t('edited')}),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  // removeArticle ({ commit }, article) {
  //   return new Promise((resolve, reject) => {
  //     api.delete('/articles/' + article.id)
  //       .then(response => {
  //         commit('REMOVE_ARTICLE', article)
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         console.log('Error', error)
  //         reject(error)
  //       })
  //   })
  // }

}