import moment from 'moment'

export default {
  getSalaries: (state) => state.salaries,
  getSalary: (state) => (id) => {
    return state.salaries.find(salary => salary.id === parseInt(id))
  },
  getSalariesContext: (state) => state.salariesContext,
  getEmptySalary: (state) => JSON.parse(JSON.stringify(state.emptySalary)),
  salariesNotCompletelyPaid: (state) => {
    let salaries = state.salaries.filter(salary => salary._paymentStatus != 'Paid')
    salaries.sort((a,b) => {return new Date(b.month)- new Date(a.month)})

    return salaries

    // return state.salaries.filter(salary => salary._paymentStatus != 'Paid')
  },
  getSalariesBetweenDates: (state) => (dates) => {
    return state.salaries.filter(s => dates.start <= moment(s.month) && moment(s.month) <= dates.end)
  }
}