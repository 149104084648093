import i18n from '@/libs/i18n'

const translateTerms = (offer) => {
  if ('paymentMethod' in offer) {
    offer.paymentMethod.name = i18n.t(offer.paymentMethod.name)
  }
  if ('paymentTerm' in offer) {
    offer.paymentTerm.term = i18n.t(offer.paymentTerm.term)
  }
}

export default {
  SET_OFFERS (state, offers) {
    offers.forEach(q => {
      translateTerms(q)
    })

    state.offers = offers
  },
  ADD_OFFER (state, offer) {
    translateTerms(offer)

    state.offers.push(offer)
  },
  UPDATE_OFFER(state, offer) {
    translateTerms(offer)

    let index = state.offers.findIndex(i => i.id == offer.id)
    state.offers.splice(index, 1, JSON.parse(JSON.stringify(offer)))
  },
  REMOVE_OFFER (state, offer) {
    state.offers = state.offers.filter(item => item.id != offer.id)

    // let index = state.offers.findIndex(i => i.id === offer.id)
    // state.offers.splice(index, 1)
  },
}