import { computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import moment from 'moment'
import { sortCompare } from './utils'
import api from '../libs/axios'

export default function useAPI () {
  const defaultLimit = 100
  // ======================================================================
  // ==================               FETCH              ==================
  // ======================================================================
  const fetchAutocompleteBankAccounts = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount/getAutocompleteBankAccounts', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteCompanies = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('company/getAutocompleteCompanies', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteContacts = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('contact/getAutocompleteContacts', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompleteDocumentCategories = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('documentCategory/getAutocompleteDocumentCategories', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompletePayments = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('payment/getAutocompletePayments', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAutocompletePaymentMethods = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('paymentMethod/getAutocompletePaymentMethods', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchArticles = (limit = defaultLimit) => {
    return new Promise((resolve, reject) => {
      store.dispatch('article/getArticles', { limit: limit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccounts = (limit = defaultLimit) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount/getBankAccounts', { limit: limit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccounts2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/getBankAccounts', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccounts2CheckForTransactions = (bankAccount = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/updateBankAccountCheckForTransactions', bankAccount)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccountIndicators = (bankAccount = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/getBankAccountIndicators', bankAccount)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankAccountsIndicators = (bankAccount = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount2/getBankAccountsIndicators', bankAccount)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchBankTransactions = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankTransaction/getBankTransactions', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchCalendars = (payload) => {
    // store.dispatch('calendar/getCalendars', payload)
    return new Promise((resolve, reject) => {
      store.dispatch('calendar/getCalendars', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchCompanies = (companyId) => {
    if (companyId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('company/getCompany', companyId)
          .then((company) => {
            resolve(company)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('company/getCompanies', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }

  }
  const fetchEmployeeContactTypes = () => {
    store.dispatch('employeeContractType/getEmployeeContractTypes')
  }
  // const fetchEvents = (limit = defaultLimit) => {
  //   return new Promise((resolve, reject) => {
  //     store.dispatch('event/getEvents', { limit: limit })
  //       .then(() => {
  //         resolve()
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // }
  const fetchEvents = (dates, type = null) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event/getEventsByDates', { dates: dates, type: type, limit: defaultLimit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchExpensesIndicator = (dates, type = null) => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getExpensesIndicator', { dates: dates })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAwaitingExportExpensesIndicator = () => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getAwaitingExportExpensesIndicator')
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchHRIndicator = (employee, dates) => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getHRIndicator', { employee: employee, dates: dates })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchIncomingInvoices = (incomingInvoiceId) => {
    if (incomingInvoiceId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('incomingInvoice/getIncomingInvoice', incomingInvoiceId)
          .then((incomingInvoice) => {
            resolve(incomingInvoice)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('incomingInvoice/getIncomingInvoices', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchIncomingInvoices2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('incomingInvoice2/getIncomingInvoices', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchOffers = (offerId) => {
    if (offerId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('offer/getOffer', offerId)
          .then((offer) => {
            resolve(offer)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('offer/getOffers', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchOrders = (orderId) => {
    if (orderId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('order/getOrder', orderId)
          .then((order) => {
            resolve(order)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('order/getOrders', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchOutgoingInvoices = (outgoingInvoiceId) => {
    if (outgoingInvoiceId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('outgoingInvoice/getOutgoingInvoice', outgoingInvoiceId)
          .then((user) => {
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('outgoingInvoice/getOutgoingInvoices', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchOutgoingInvoices2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/getOutgoingInvoices', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchPaymentMethods = () => {
    store.dispatch('paymentMethod/getPaymentMethods')
  }
  const fetchPaymentTerms = () => {
    store.dispatch('paymentTerm/getPaymentTerms')
  }
  const fetchSalaries = (salaryId) => {
    if (salaryId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('salary/getSalary', salaryId)
          .then((user) => {
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('salary/getSalaries', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }

  const fetchSalaries2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('salary2/getSalaries', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchSalesIndicator = (payload) => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getSalesIndicator', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchAwaitingExportSalesIndicator = () => {
    return new Promise((resolve, reject) => {
      store.dispatch('indicator/getAwaitingExportSalesIndicator')
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const fetchSocialSecurityContributions = (limit = defaultLimit) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution/getSocialSecurityContributions', { limit: limit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchSocialSecurityContributions2 = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution2/getSocialSecurityContributions', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchTasks = (limit = defaultLimit) => {
    return new Promise((resolve, reject) => {
      store.dispatch('task/getTasks', { limit: limit })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchTickets = (payload = {}) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/getTickets', payload)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchTicketsIndicator = (dates, type = null) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/getTicketsIndicator', { dates: dates })
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchUsers = (userId) => {
    if (userId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('user/getUser', userId)
          .then((user) => {
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('user/getUsers', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
  const fetchVats = () => {
    store.dispatch('vat/getVats')
  }
  const fetchWorkflows = (workflowId) => {
    if (workflowId != null) {
      return new Promise((resolve, reject) => {
        store.dispatch('workflow/getWorkflow', workflowId)
          .then((workflow) => {
            resolve(workflow)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        store.dispatch('workflow/getWorkflows', { limit: defaultLimit })
          .then(() => {
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }

  // ======================================================================
  // ==================              COMPUTED            ==================
  // ======================================================================
  const articles = computed(() => {
    return store.getters['article/getArticles']
  })

  const autocompleteBankAccounts = computed(() => {
    return store.getters['bankAccount/getAutocompleteBankAccounts']
  })

  const autocompleteCompanies = computed(() => {
    return store.getters['company/getAutocompleteCompanies']
  })

  const autocompleteContacts = computed(() => {
    return store.getters['contact/getAutocompleteContacts']
  })

  const autocompleteDocumentCategories = computed(() => {
    return store.getters['documentCategory/getAutocompleteDocumentCategories']
  })

  const autocompletePayments = computed(() => {
    return store.getters['payment/getAutocompletePayments']
  })

  const autocompletePaymentMethods = computed(() => {
    return store.getters['paymentMethod/getAutocompletePaymentMethods']
  })

  const isLoading = computed(() => {
    return store.getters['app/isLoading']
  })

  const bankAccounts = computed(() => {
    return store.getters['bankAccount/getBankAccounts']
  })

  const bankAccounts2 = computed(() => {
    return store.getters['bankAccount2/getBankAccounts']
  })

  const bankAccountsContext = computed(() => {
    return store.getters['bankAccount2/getBankAccountsContext']
  })

  const bankAccountIndicators = computed(() => {
    return store.getters['bankAccount2/getBankAccountIndicators']
  })

  const bankAccountsIndicators = computed(() => {
    return store.getters['bankAccount2/getBankAccountsIndicators']
  })

  const bankTransactions = computed(() => {
    return store.getters['bankTransaction/getBankTransactions']
  })

  const bankTransactionsContext = computed(() => {
    return store.getters['bankTransaction/getBankTransactionsContext']
  })

  const contacts = computed(() => {
    let output = JSON.parse(JSON.stringify(users.value.concat(companies.value)))
    let index = 0

    output.forEach(contact => {
      contact._vSelectIndex = index

      if ('roles' in contact) {
        contact._vSelectType = 'user'
        contact._vSelectId = contact.id
      } else {
        contact._vSelectType = 'company'
        contact._vSelectId = contact.id
      }

      index++
      delete contact.id
    })
    output.sort(sortCompare('_display'))

    return output

  })

  const contactsWithMainCompany = computed(() => {
    let output = JSON.parse(JSON.stringify(users.value.concat(companiesWithMe.value)))
    let index = 0

    output.forEach(contact => {
      contact._vSelectIndex = index

      if ('roles' in contact) {
        contact._vSelectType = 'user'
        contact._vSelectId = contact.id
      } else {
        contact._vSelectType = 'company'
        contact._vSelectId = contact.id
      }

      index++
      delete contact.id
    })
    output.sort(sortCompare('_display'))

    return output
  })

  const contactsWithMe = computed(() => {
    let output = JSON.parse(JSON.stringify(users.value.concat(companiesWithMe.value)))
    let index = 0

    output.forEach(contact => {
      contact._vSelectIndex = index
      contact._vSelectId = contact.id
      index++
      delete contact.id
    })
    output.sort(sortCompare('_display'))

    return output
  })

  const companies = computed(() => {
    return store.getters['company/getCompaniesWithoutMainCompany']
  })

  const companiesWithMe = computed(() => {
    return store.getters['company/getCompanies']
  })

  const currentBreakPoint = computed(() => {
    return store.getters['app/currentBreakPoint']
  })

  const employeeContractTypes = computed(() => {
    return store.getters['employeeContractType/getEmployeeContractTypes']
  })

  const events = computed(() => {
    return store.getters['event/getEvents']
  })

  const expensesIndicator = computed(() => {
    return store.getters['indicator/getExpensesIndicator']
  })

  const awaitingExportExpensesIndicator = computed(() => {
    return store.getters['indicator/getAwaitingExportExpensesIndicator']
  })

  const hrIndicator = computed(() => {
    return store.getters['indicator/getHRIndicator']
  })

  const incomingInvoices = computed(() => {
    return store.getters['incomingInvoice/getIncomingInvoices']
  })

  const incomingInvoices2 = computed(() => {
    return store.getters['incomingInvoice2/getIncomingInvoices']
  })

  const incomingInvoicesContext = computed(() => {
    return store.getters['incomingInvoice2/getIncomingInvoicesContext']
  })

  const myCompany = computed(() => {
    return store.getters['company/getMyCompany']
  })

  const orders = computed(() => {
    return store.getters['order/getOrders']
  })

  const outgoingInvoices = computed(() => {
    return store.getters['outgoingInvoice/getOutgoingInvoices']
  })

  const outgoingInvoices2 = computed(() => {
    return store.getters['outgoingInvoice2/getOutgoingInvoices']
  })

  const outgoingInvoicesContext = computed(() => {
    return store.getters['outgoingInvoice2/getOutgoingInvoicesContext']
  })

  const payments = computed(() => {
    return store.getters['payment/getPayments']
  })

  const paymentsContext = computed(() => {
    return store.getters['payment/getPaymentsContext']
  })

  const paymentMethods = computed(() => {
    let output = []
    store.getters['paymentMethod/getPaymentMethods'].forEach(pm => {
      output.push({
        id: pm.id,
        name: i18n.t(pm.name),
      })
    })

    return output
  })

  const paymentTerms = computed(() => {
    let output = []
    store.getters['paymentTerm/getPaymentTerms'].forEach(pt => {
      output.push({
        id: pt.id,
        term: i18n.t(pt.term),
        days: pt.days
      })
    })

    return output
  })

  const offers = computed(() => {
    return store.getters['offer/getOffers']
  })

  const salaries = computed(() => {
    return store.getters['salary/getSalaries']
  })

  const salaries2 = computed(() => {
    return store.getters['salary2/getSalaries']
  })

  const salariesContext = computed(() => {
    return store.getters['salary2/getSalariesContext']
  })

  const salesIndicator = computed(() => {
    return store.getters['indicator/getSalesIndicator']
  })

  const awaitingExportSalesIndicator = computed(() => {
    return store.getters['indicator/getAwaitingExportSalesIndicator']
  })

  const socialSecurityContributions = computed(() => {
    return store.getters['socialSecurityContribution/getSocialSecurityContributions']
  })

  const socialSecurityContributions2 = computed(() => {
    return store.getters['socialSecurityContribution2/getSocialSecurityContributions']
  })

  const socialSecurityContributionsContext = computed(() => {
    return store.getters['socialSecurityContribution2/getSocialSecurityContributionsContext']
  })

  const tasks = computed(() => {
    return store.getters['task/getTasks']
  })

  const tickets = computed(() => {
    return store.getters['ticket/getTickets']
  })

  const ticketsContext = computed(() => {
    return store.getters['ticket/getTicketsContext']
  })

  const ticketsIndicator = computed(() => {
    return store.getters['ticket/getTicketsIndicator']
  })

  const users = computed(() => {
    return store.getters['user/getUsers']
  })

  const vats = computed(() => {
    return store.getters['vat/getVats']
  })

  const workflows = computed(() => {
    return store.getters['workflow/getWorkflows']
  })

  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================

  const getCountry = (countryCode) => {
    return store.getters['address/getCountry'](countryCode)
  }

  const getContact = (userOrCompanyEntity) => {
    return contactsWithMainCompany.value.find(ct => {
      return (
        'roles' in userOrCompanyEntity &&
        ct._vSelectType == 'user' &&
        ct._vSelectId == userOrCompanyEntity.id
      ) || (
        !('roles' in userOrCompanyEntity) &&
        ct._vSelectType == 'company' &&
        ct._vSelectId == userOrCompanyEntity.id
      )

    })
  }

  const getUserContract = (user, searchedDate = null) => {
    console.log(user)
    if (!('contracts' in user) || user.contracts.length == 0) return null
    else {
      let date = moment(searchedDate)
      if (searchedDate == null) {
        date = moment()
      }

      return user.contracts.find(c => moment(c.start) <= date && (c.end == null || date <= moment(c.end)))
    }
  }

  const performApiCall = (url, payload) => {
    return new Promise((resolve, reject) => {

      const { page, perPage, searchCriteria, sortColumn, sortOrder } = payload
      let params = new URLSearchParams()

      if (page != null) {params.append('page', page)}
      if (perPage != null) {params.append('per_page', perPage)}
      if (searchCriteria != null && searchCriteria.length != 0) {
        searchCriteria.forEach((item, index) => {
          for (const key in item) {
            params.append('search_criteria[' + index + '][' + key + ']', item[key])
          }
        })
      }
      if (sortColumn != null) {params.append('sort_column', sortColumn)}
      if (sortOrder != null) {params.append('sort_order', sortOrder)}

      api.get(url + '?' + params)
        .then(response => {
          // commit('SET_AUTOCOMPLETECONTACTS', response.data.results)
          // store.commit('app/TOGGLE_LOADING', false)
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          // reject(err)
        })
    })
  }

  // const getMileageAllowanceCost = (user, distance) => {
  //   if (user.horsePower != null) {
  //     if (user.horsePower <= 3) {
  //       return parseFloat(distance) * 0.502
  //     } else if (user.horsePower == 4) {
  //       return parseFloat(distance) * 0.575
  //     } else if (user.horsePower == 5) {
  //       return parseFloat(distance) * 0.603
  //     } else if (user.horsePower == 6) {
  //       return parseFloat(distance) * 0.531
  //     } else {
  //       return parseFloat(distance) * 0.661
  //     }
  //   } else {
  //     return 0
  //   }
  // }

  return {
    // Fetchers
    fetchAutocompleteBankAccounts,
    fetchAutocompleteCompanies,
    fetchAutocompleteContacts,
    fetchAutocompleteDocumentCategories,
    fetchAutocompletePayments,
    fetchAutocompletePaymentMethods,
    fetchArticles,
    fetchBankAccounts,
    fetchBankAccounts2,
    fetchBankAccounts2CheckForTransactions,
    fetchBankAccountIndicators,
    fetchBankAccountsIndicators,
    fetchBankTransactions,
    fetchCalendars,
    fetchCompanies,
    fetchEmployeeContactTypes,
    fetchEvents,
    fetchExpensesIndicator,
    fetchAwaitingExportExpensesIndicator,
    fetchHRIndicator,
    fetchIncomingInvoices,
    fetchIncomingInvoices2,
    fetchOrders,
    fetchOutgoingInvoices,
    fetchOutgoingInvoices2,
    fetchPaymentMethods,
    fetchPaymentTerms,
    fetchOffers,
    fetchSalaries,
    fetchSalaries2,
    fetchSalesIndicator,
    fetchAwaitingExportSalesIndicator,
    fetchSocialSecurityContributions,
    fetchSocialSecurityContributions2,
    fetchTasks,
    fetchTickets,
    fetchTicketsIndicator,
    fetchUsers,
    fetchVats,
    fetchWorkflows,

    // Computed
    articles,
    autocompleteBankAccounts,
    autocompleteCompanies,
    autocompleteContacts,
    autocompleteDocumentCategories,
    autocompletePayments,
    autocompletePaymentMethods,
    bankAccountIndicators,
    bankAccountsIndicators,
    bankAccounts,
    bankAccounts2,
    bankAccountsContext,
    bankTransactions,
    bankTransactionsContext,
    contacts,
    contactsWithMe,
    contactsWithMainCompany,
    companies,
    currentBreakPoint,
    employeeContractTypes,
    events,
    expensesIndicator,
    awaitingExportExpensesIndicator,
    hrIndicator,
    incomingInvoices,
    incomingInvoices2,
    incomingInvoicesContext,
    isLoading,
    myCompany,
    orders,
    outgoingInvoices,
    outgoingInvoices2,
    outgoingInvoicesContext,
    payments,
    paymentsContext,
    paymentMethods,
    paymentTerms,
    salaries,
    salaries2,
    salariesContext,
    salesIndicator,
    awaitingExportSalesIndicator,
    socialSecurityContributions,
    socialSecurityContributions2,
    socialSecurityContributionsContext,
    tasks,
    tickets,
    ticketsContext,
    ticketsIndicator,
    users,
    offers,
    vats,
    workflows,

    // Methods
    getCountry,
    getContact,
    getUserContract,
    // getMileageAllowanceCost,
    performApiCall,
  }
}
