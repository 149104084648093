import api from '@/libs/axios'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'

export default {
  login ({ commit }, user) {
    return new Promise((resolve, reject) => {
      api.post('/login_check', user)
        .then(response => {
          if (response.data.userData == "Unauthorized") {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: capitalize(i18n.t('error')),
                icon: 'times',
                text: i18n.t('You are not authorized to login'),
                variant: 'danger'
              },
            })
          } else {
            commit('LOGIN', response.data)
          }
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    commit('LOGOUT')
    return new Promise((resolve) => {
      resolve()
    })
  },
  sendResetPasswordLink ({ commit }, user) {
    return new Promise((resolve, reject) => {
      api.post('/reset-password/reseting', user)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  resetPassord ({ commit }, payload) {
    let resetToken = payload.resetToken
    let user = payload.datas

    return new Promise((resolve, reject) => {
      api.post('/reset-password/reset/' + resetToken, user)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  changePassord ({ commit }, payload) {
    let currentPassword = payload.currentPassword
    let plainPassword = payload.plainPassword
    let plainPassword2 = payload.plainPassword2

    if (plainPassword == plainPassword2) {
      let datas = new FormData()
      datas.set('currentPassword', currentPassword)
      datas.set('plainPassword', plainPassword)

      return new Promise((resolve, reject) => {
        api.post('/change-password', datas)
          .then(response => {

            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: capitalize(i18n.t('success')),
                icon: 'check',
                text: i18n.t('The password has been ', { crud: i18n.t('edited') }),
                variant: 'success'
              },
            })

            resolve(response)
          })
          .catch(err => {
            console.log('Error', err)

            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: capitalize(i18n.t('error')),
                icon: 'times',
                text: i18n.t('BadPassword'),
                variant: 'danger'
              },
            })

            reject(err)
          })
      })
    } else {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: capitalize(i18n.t('error')),
          icon: 'times',
          text: i18n.t('Passwords do not match'),
          variant: 'danger'
        },
      })
    }


  },
  validateRegistration ({ commit }, payload) {
    let invitationCode = payload.invitationCode
    let user = payload.datas

    return new Promise((resolve, reject) => {
      api.post('/validate-registration/' + invitationCode, user)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },

}