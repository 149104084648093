import api from '@/libs/axios'
import { cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import useAppConfig from '../../utils/useAppConfig'
import store from '../index'

export default {
  getSocialSecurityContributions ({ commit }, payload) {
    store.commit('app/TOGGLE_LOADING', true)

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/socialSecurityContributions'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          commit('SET_SOCIALSECURITYCONTRIBUTIONS', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit
            recursiveCall(url, 'socialSecurityContribution', limit, offset, commit, 1).then(() => {
              store.commit('app/TOGGLE_LOADING', false)
              resolve(true)
            })
          } else {
            store.commit('app/TOGGLE_LOADING', false)
            resolve(true)
          }

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addSocialSecurityContribution ({ commit, state }, socialSecurityContribution) {
    return await cleanEntityToSend(socialSecurityContribution, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/socialSecurityContributions', clonedEntityToSend)
            .then(response => {
              commit('ADD_SOCIALSECURITYCONTRIBUTION', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The social security contribution has been ', { crud: i18n.t('createdE') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateSocialSecurityContribution ({ commit, state }, socialSecurityContribution) {
    return await cleanEntityToSend(socialSecurityContribution, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/socialSecurityContributions/' + socialSecurityContribution.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_SOCIALSECURITYCONTRIBUTION', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The social security contribution has been ', { crud: i18n.t('editedE') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeSocialSecurityContribution ({ commit }, socialSecurityContribution) {
    return new Promise((resolve, reject) => {
      api.delete('/socialSecurityContributions/' + socialSecurityContribution.id)
        .then(response => {
          commit('REMOVE_SOCIALSECURITYCONTRIBUTION', socialSecurityContribution)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The social security contribution has been ', { crud: i18n.t('removedE') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}