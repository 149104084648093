import api from '@/libs/axios'

export default {
  getVats ({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.vats.length == 0) {
        api.get('/vats')
          .then(response => {
            commit('SET_VATS', response.data)
            resolve(response)
          })
          .catch(err => {
            console.log('Error', err)
            reject(err)
          })
      } else {
        resolve(true)
      }
    })
  },

}