export default {
  workflows: [],
  emptyWorkflow: {
    events: [],
    incomingInvoices: [],
    outgoingInvoices: [],
    offers: [],
    opportunityDate: (new Date()).toISOString().slice(0, 10),
    startDate: (new Date()).toISOString().slice(0, 10),
  },
  params: {
    entity: [
      'events',
      'customerCompany',
      'customerIndividual',
      'incomingInvoices',
      'outgoingInvoices',
      'quotes',
      'assignedTo',
      'offers',
      'orders',
      'files',
      'responsible',
      'tasks'
    ],
    collection: [
      'activities',
      'notes',
      'phases'
    ]
  }
}