export default [
  // Dashboard
  {
    path: '/hrdashboard',
    name: 'Human Resources dashboard',
    component: () => import('@/views/humanResources/dashboard/Dashboard.vue'),
    meta: {
      resource: 'HRDashboard',
      action: 'manage',
    }
  },
  // Employees
  {
    path: '/employees',
    name: 'Employees',
    component: () => import('@/views/humanResources/employees/Employees.vue'),
    meta: {
      resource: 'Employees',
      action: 'manage',
    }
  },
  {
    path: '/employees/:employeeId/edit',
    name: 'Employee edit',
    component: () => import('@/views/humanResources/employees/EmployeeEdit.vue'),
    meta: {
      navActiveLink: 'Employees',
      resource: 'Employees',
      // action: 'read',
    }
  },
  // Salaries
  {
    path: '/salaries',
    name: 'Salaries',
    component: () => import('@/views/humanResources/salaries/Salaries.vue'),
    meta: {
      resource: 'Salaries',
      // action: 'read',
    }
  },
  // Salaries Beta
  {
    path: '/salaries2',
    name: 'Salaries2',
    component: () => import('../../views/humanResources/salaries2/Salaries.vue'),
    meta: {
      resource: 'Salaries',
      // action: 'read',
    }
  },
  // Holidays
  {
    path: '/holidays',
    name: 'Holidays',
    component: () => import('@/views/humanResources/holidays/Holidays.vue'),
    meta: {
      resource: 'Holidays',
      // action: 'read',
    }
  },
  // SocialSecurityContributions
  {
    path: '/socialSecurityContributions',
    name: 'SocialSecurityContributions',
    component: () => import('@/views/humanResources/socialSecurityContributions/SocialSecurityContributions.vue'),
    meta: {
      resource: 'SocialSecurityContributions',
      action: 'manage',
    }
  },
  // SocialSecurityContributions Beta
  {
    path: '/charges',
    name: 'Charges',
    component: () => import('../../views/humanResources/charges/Charges.vue'),
    meta: {
      resource: 'Charges',
      action: 'manage',
    }
  },
  // Movements
  {
    path: '/movements',
    name: 'Movements',
    component: () => import('@/views/humanResources/movements/Movements.vue'),
    meta: {
      resource: 'Movements',
      action: 'manage',
    }
  },
]
