export default {
  autocompletePayments: [],
  payments: [],
  emptyPayment: {
    date: (new Date()).toISOString().slice(0, 10),
  },
  params: {
    entity: [
      'attachment',
      'attachmentCategory',
      'bankAccount',
      'bankTransaction',
      'incomingInvoice',
      'outgoingInvoice',
      'paymentMethod',
      'salary',
      'socialSecurityContribution'
    ],
    collection: [
      'paymentRelations',
    ]
  },
  paymentsContext: {}
}