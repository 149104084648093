import state from './bankTransactionState';
import mutations from './bankTransactionMutations';
import actions from './bankTransactionActions';
import getters from "./bankTransactionGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}