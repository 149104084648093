import moment from 'moment'

export default {
  autocompleteBankAccounts: [],
  bankAccounts: [],
  emptyBankAccount: {},
  bankAccountIndicators: {},
  bankAccountsIndicators: {},
  params: {
    entity: [
      'bankOperations'
    ],
    collection: []
  },
  banks: [
    {
      title: 'LCL',
      value: 'LCL'
    },
    {
      title: 'Crédit Agricole',
      value: 'CA'
    },
    {
      title: 'Paypal',
      value: 'Paypal'
    },
    {
      title: 'Banque Populaire',
      value: 'BPP'
    },
    {
      title: 'Banque Postale',
      value: 'BPS'
    },
    {
      title: 'BNP Paribas',
      value: 'BNP'
    },
    {
      title: 'Boursorama Banque',
      value: 'BB'
    },
    {
      title: 'Caisse d\'épargne',
      value: 'CE'
    },
    {
      title: 'CIC',
      value: 'CIC'
    },
    {
      title: 'Crédit Mutuel',
      value: 'CM'
    },
    {
      title: 'Société Générale',
      value: 'SG'
    },

  ],
  bankAccountsContext: {}
}