export default {
  SET_SALESINDICATOR (state, salesIndicator) {
    state.salesIndicator = salesIndicator
  },
  SET_AWAITINGEXPORTSALESINDICATOR (state, awaitingExportSalesIndicator) {
    state.awaitingExportSalesIndicator = awaitingExportSalesIndicator
  },
  SET_EXPENSESINDICATOR (state, expensesIndicator) {
    state.expensesIndicator = expensesIndicator
  },
  SET_AWAITINGEXPORTEXPENSESINDICATOR (state, awaitingExportExpensesIndicator) {
    state.awaitingExportExpensesIndicator = awaitingExportExpensesIndicator
  },
  SET_HRINDICATOR (state, hrIndicator) {
    state.hrIndicator = hrIndicator
  },
}