import api from '@/libs/axios'
import { cleanEntityToSend, addOrUpdateInList } from '../../utils/form'
import { eventToCalendarEvent } from '../../utils/utils'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '@/store'
import useAppConfig from '../../utils/useAppConfig'
import moment from 'moment'

export default {
  getEventsByDates ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    let url = '/events'
    let dates = payload.dates
    let type = payload.type

    return new Promise((resolve, reject) => {
      if (dates.start == null && dates.end == null && type != null) {
        const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()

        let limit = payload.limit
        let offset = 0

        return new Promise((resolve, reject) => {
          let url = '/events'
          let params = new URLSearchParams({ limit: limit, offset: offset, type:type })

          api.get(url + '?' + params)
            .then(response => {
              commit('SET_EVENTS', response.data)

              if (response.data.length == limit) {
                offset += limit
                limit = recursiveCallIterativeLimit
                recursiveCall(url, 'event', limit, offset, state.events, commit, 1, type).then(() => {
                  store.commit('app/REMOVE_LOADER')
                  resolve(true)
                })
              } else {
                store.commit('app/REMOVE_LOADER')
                resolve(true)
              }

            })
            .catch(err => {
              console.log('Error', err)
              reject(err)
            })
        })
      } else {
        let start = moment(dates.start).format('YYYY-MM-DD')
        let end = moment(dates.end).format('YYYY-MM-DD')
        let params = new URLSearchParams({ limit: null, offset: null, start: start, end: end })

        api.get(url + '?' + params)
          .then(response => {
            commit('SET_EVENTS', response.data)

            // addOrUpdateInList(response.data, state.events, 'event', commit)

            store.commit('app/REMOVE_LOADER')
            resolve(true)

          })
          .catch(err => {
            console.log('Error', err)
            reject(err)
          })
      }

      // let url = '/events'
      // let dates = payload.dates
      // let start = moment(dates.start).format('YYYY-MM-DD')
      // let end = moment(dates.end).format('YYYY-MM-DD')
      // // let params = new URLSearchParams({ limit: null, offset: null, start: start, end: end })
      // let params = new URLSearchParams({ limit: null, offset: null, start: null, end: null })
      //
      // api.get(url + '?' + params)
      //   .then(response => {
      //     commit('SET_EVENTS', response.data)
      //
      //     // addOrUpdateInList(response.data, state.events, 'event', commit)
      //
      //     store.commit('app/REMOVE_LOADER')
      //     resolve(true)
      //
      //   })
      //   .catch(err => {
      //     console.log('Error', err)
      //     reject(err)
      //   })
    })
  },
  async addEvent ({ commit, state }, event) {
    return await cleanEntityToSend(event, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/events', clonedEntityToSend)
            .then(response => {
              commit('ADD_EVENT', response.data)
              store.commit('calendar/ADD_EVENT', eventToCalendarEvent(response.data))

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The event has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateEvent ({ commit, state }, event) {
    return await cleanEntityToSend(event, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/events/' + event.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_EVENT', response.data)
              store.commit('calendar/UPDATE_EVENT', eventToCalendarEvent(response.data))

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The event has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeEvent ({ commit }, event) {
    return new Promise((resolve, reject) => {
      api.delete('/events/' + event.id)
        .then(response => {
          commit('REMOVE_EVENT', event)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The event has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}