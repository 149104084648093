import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '@/store'
import useAppConfig from '../../utils/useAppConfig'
import useAPI from '../../utils/useAPI'

export default {
  // Payload : page, perPage, searchCriteria, sortColumn, sortOrder
  getSalaries ({ commit, state }, payload) {
    const { performApiCall } = useAPI()
    store.commit('app/TOGGLE_LOADING', true)

    return new Promise((resolve, reject) => {
      let url = '/salaries2'

      performApiCall(url, payload)
        .then(response => {
          commit('SET_SALARIES', response.results)
          commit('SET_SALARIESCONTEXT', response.page_context)
          store.commit('app/TOGGLE_LOADING', false)
          resolve(true)
        })
    })
  },


  getSalary ({ commit, state }, salaryId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/salaries/'+salaryId)
        .then(response => {
          addOrUpdateInList(response.data, state.salaries, 'salary',commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addSalary ({ commit, state }, salary) {
    let employee = salary.employee.firstName + " " + salary.employee.name

    return await cleanEntityToSend(salary, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/salaries', clonedEntityToSend)
            .then(response => {

              if (response.data == "exist") {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: capitalize(i18n.t('warning')),
                    icon: 'triangle-exclamation',
                    text: i18n.t('The salary exist', { employee: employee }),
                    variant: 'warning'
                  },
                })
              } else {
                commit('ADD_SALARY', response.data)

                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: capitalize(i18n.t('success')),
                    icon: 'check',
                    text: i18n.t('The salary has been ', { crud: i18n.t('created') }),
                    variant: 'success'
                  },
                })
              }

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateSalary ({ commit, state }, salary) {
    return await cleanEntityToSend(salary, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/salaries/' + salary.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_SALARY', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The salary has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeSalary ({ commit }, salary) {
    return new Promise((resolve, reject) => {
      api.delete('/salaries/' + salary.id)
        .then(response => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The salary has been ', { crud: i18n.t('deleted') }),
              variant: 'success'
            },
          })

          commit('REMOVE_SALARY', salary)
          resolve(response)
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t('UnableToDelete'),
                icon: 'times',
                text: i18n.t('This salary is linked to invoices'),
                variant: 'danger'
              },
            })
          }
          reject(error)
        })
    })
  }

}