import i18n from '../../libs/i18n'

const translateTerms = (payment) => {
  if ('paymentMethod' in payment) {
    payment.paymentMethod.name = i18n.t(payment.paymentMethod.name)
  }
}

export default {
  SET_PAYMENTS (state, payments) {
    state.payments = payments
  },
  ADD_PAYMENT (state, payment) {
    // state.payments.push(payment)
    state.payments.unshift(payment)
  },
  UPDATE_PAYMENT (state, payment) {
    translateTerms(payment)
    let index = state.payments.findIndex(i => i.id === payment.id)
    state.payments.splice(index, 1, payment)
  },
  REMOVE_PAYMENT (state, payment) {
    state.payments = state.payments.filter(item => item.id != payment.id)

    // let index = state.payments.findIndex(i => i.id === payment.id)
    // state.payments.splice(index, 1)
  },
  SET_PAYMENTSCONTEXT (state, paymentsContext) {
    state.paymentsContext = paymentsContext
  },
  UPDATE_PAYMENTSCONTEXTTOTALRESULT (state, isAddPayment) {
    if (isAddPayment) {
      state.paymentsContext.total_results++
    } else {
      state.paymentsContext.total_results--
    }
  },
  SET_AUTOCOMPLETEPAYMENTS (state, autocompletePayments) {
    state.autocompletePayments = autocompletePayments
  },
}