import Vue from 'vue'
import axios from 'axios'

const accessToken = localStorage.getItem('accessToken')
// const apiURL = 'http://192.168.1.232:8000/'
// const apiURL = 'http://192.168.1.10:8000/'
// const apiURL = 'http://192.168.251.125:8000/'
// const apiURL = 'https://apierp.hmt-france.com/'
const apiURL = 'https://apiERPbeta.hmt-france.com/'
// const apiURL = 'https://api.erp-coopti.hmt-france.com/'

const api = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiURL,
  // timeout: 90000,
  headers: { 'Authorization': 'Bearer ' + accessToken }
})

Vue.prototype.$http = api
export default api