import i18n from '@/libs/i18n'

const translatePaymentsMethodsTerms = (term) => {
  if ('paymentMethod' in term) {
    term.paymentMethod.name = i18n.t(term.paymentMethod.name)
  }
}

export default {
  SET_INCOMINGINVOICES (state, incomingInvoices) {
    incomingInvoices.forEach(ii => {
      translatePaymentsMethodsTerms(ii)
      if ('bankOperations' in ii) {
        ii.bankOperations.forEach(bo => {
          translatePaymentsMethodsTerms(bo)
        })
      }
    })

    state.incomingInvoices = incomingInvoices
  },
  ADD_INCOMINGINVOICE (state, incomingInvoice) {
    translatePaymentsMethodsTerms(incomingInvoice)
    if ('bankOperations' in incomingInvoice) {
      incomingInvoice.bankOperations.forEach(bo => {
        translatePaymentsMethodsTerms(bo)
      })
    }

    state.incomingInvoices.push(incomingInvoice)
  },
  UPDATE_INCOMINGINVOICE (state, incomingInvoice) {
    translatePaymentsMethodsTerms(incomingInvoice)
    incomingInvoice.bankOperations.forEach(bo => {
      translatePaymentsMethodsTerms(bo)
    })

    let index = state.incomingInvoices.findIndex(i => i.id == incomingInvoice.id)
    state.incomingInvoices.splice(index, 1, JSON.parse(JSON.stringify(incomingInvoice)))
  },
  REMOVE_INCOMINGINVOICE (state, incomingInvoice) {
    state.incomingInvoices = state.incomingInvoices.filter(item => item.id != incomingInvoice.id)

    // let index = state.incomingInvoices.findIndex(i => i.id === incomingInvoice.id)
    // state.incomingInvoices.splice(index, 1)
  },
}