import i18n from '@/libs/i18n'

const translateTerms = (item) => {
  if ('paymentMethod' in item) {
    item.paymentMethod.name = i18n.t(item.paymentMethod.name)
  }
  if ('paymentTerm' in item && item.paymentTerm != null) {
    item.paymentTerm.term = i18n.t(item.paymentTerm.term)
  }
}

export default {
  SET_ORDERS (state, orders) {
    orders.forEach(o => {
      translateTerms(o)
      if ('_linkedIncomingInvoices' in o) {
        o._linkedIncomingInvoices.forEach(i => {
          translateTerms(i)
        })
      }
    })
    state.orders = orders
  },
  ADD_ORDER (state, order) {
    translateTerms(order)

    state.orders.push(order)
  },
  UPDATE_ORDER(state, order) {
    translateTerms(order)

    let index = state.orders.findIndex(i => i.id == order.id)
    state.orders.splice(index, 1, JSON.parse(JSON.stringify(order)))
  },
  REMOVE_ORDER (state, order) {
    state.orders = state.orders.filter(item => item.id != order.id)

    // let index = state.orders.findIndex(i => i.id === order.id)
    // state.orders.splice(index, 1)
  },
}