import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import address from './address/address'
import app from './app/app'
import article from './article/article'
import auth from './auth/auth'
import bankAccount from './bankAccount/bankAccount'
import bankAccount2 from './bankAccount2/bankAccount'
import bankOperation from './bankOperation/bankOperation'
import bankStatement from './bankStatement/bankStatement'
import bankTransaction from './bankTransaction/bankTransaction'
import calendar from './calendar/calendar'
import company from './company/company'
import contact from './contact/contact'
import document from './document/document'
import documentCategory from './documentCategory/documentCategory'
import employeeContractType from './employeeContractType/employeeContractType'
import event from './event/event'
import incomingInvoice from './incomingInvoice/incomingInvoice'
import incomingInvoice2 from './incomingInvoice2/incomingInvoice'
import indicator from './indicator/indicator'
import order from './order/order'
import outgoingInvoice from './outgoingInvoice/outgoingInvoice'
import outgoingInvoice2 from './outgoingInvoice2/outgoingInvoice'
import payment from './payment/payment'
import paymentMethod from './paymentMethod/paymentMethod'
import paymentTerm from './paymentTerm/paymentTerm'
import offer from './offer/offer'
import salary from './salary/salary'
import salary2 from './salary2/salary'
import socialSecurityContribution from './socialSecurityContribution/socialSecurityContribution'
import socialSecurityContribution2 from './socialSecurityContribution2/socialSecurityContribution'
import task from './task/task'
import ticket from './ticket/ticket'
import user from './user/user'
import vat from './vat/vat'
import workflow from './workflow/workflow'

export default new Vuex.Store({
  modules: {
    address: address,
    app: app,
    article: article,
    auth: auth,
    bankAccount: bankAccount,
    bankAccount2: bankAccount2,
    bankOperation: bankOperation,
    bankStatement: bankStatement,
    bankTransaction: bankTransaction,
    calendar: calendar,
    company: company,
    contact: contact,
    document: document,
    documentCategory: documentCategory,
    employeeContractType: employeeContractType,
    event: event,
    incomingInvoice: incomingInvoice,
    incomingInvoice2: incomingInvoice2,
    indicator: indicator,
    order: order,
    outgoingInvoice: outgoingInvoice,
    outgoingInvoice2: outgoingInvoice2,
    payment: payment,
    paymentMethod: paymentMethod,
    paymentTerm: paymentTerm,
    offer: offer,
    salary: salary,
    salary2: salary2,
    socialSecurityContribution: socialSecurityContribution,
    socialSecurityContribution2: socialSecurityContribution2,
    task: task,
    ticket: ticket,
    user: user,
    vat: vat,
    workflow: workflow
  }
})