import state from './contactState';
import mutations from './contactMutations';
import actions from './contactActions';
import getters from "./contactGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}