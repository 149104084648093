import store from '@/store'
import { getUserData, eventToCalendarEvent, getRoute } from '../../utils/utils'
import useAPI from '../../utils/useAPI'

export default {

  getCalendars ({ commit, state }, payload) {
    // console.log(payload)

    let databaseEvents = payload.databaseEvents
    let dates = payload.dates

    if (typeof databaseEvents != 'undefined') {
      let selectedCalendars = []
      let events = []

      if (databaseEvents != null) {
        // console.log(databaseEvents)
        databaseEvents.forEach(databaseEvent => {
          if (databaseEvent.isValidated != false) {
            events.push(eventToCalendarEvent(databaseEvent))
          }

        })
        selectedCalendars.push(getUserData().firstName + ' ' + getUserData().name)
      }
      commit('SET_SELECTED_CALENDARS', selectedCalendars)
      commit('SET_EVENTS', events)
    } else {
      const { fetchEvents } = useAPI()

      // if (dates.view == "month" || dates.view == "list") {
      //   dates.start = new Date(dates.start.setMonth(dates.start.getMonth()-1))
      //   dates.end = new Date(dates.end.setMonth(dates.end.getMonth()+1))
      // } else if (dates.view == "week") {
      //   dates.start = new Date(dates.start.setDate(dates.start.getDate()-7))
      //   dates.end = new Date(dates.end.setDate(dates.end.getDate()+7))
      // } else if (dates.view == "day") {
      //   dates.start = new Date(dates.start.setDate(dates.start.getDate()-1))
      //   dates.end = new Date(dates.end.setDate(dates.end.getDate()+1))
      // }

      fetchEvents(dates)
        .then(() => {
          let events = []

          store.getters['event/getEvents'].forEach(event => {
            if (event.isValidated != false) {
              events.push(eventToCalendarEvent(event))
            }
          })

          let selectedCalendars = []
          if (state.selectedCalendars.length == 0) {
            selectedCalendars.push(getUserData().firstName + ' ' + getUserData().name)
          } else {
            selectedCalendars = JSON.parse(JSON.stringify(state.selectedCalendars))
          }
          commit('SET_SELECTED_CALENDARS', selectedCalendars)

          commit('SET_EVENTS', events)

        })
    }

  },
}