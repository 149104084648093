import state from './paymentState';
import mutations from './paymentMutations';
import actions from './paymentActions';
import getters from "./paymentGetters";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}