import api from '@/libs/axios'
import { addOrUpdateInList, cleanEntityToSend } from '../../utils/form'
import Vue from 'vue'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'
import { capitalize } from '@/utils/filter'
import i18n from '@/libs/i18n'
import store from '../index'
import useAppConfig from '../../utils/useAppConfig'

export default {
  getWorkflows ({ commit, state }, payload) {
    store.commit('app/ADD_LOADER')

    const { recursiveCallIterativeLimit, recursiveCall } = useAppConfig()
    let limit = payload.limit
    let offset = 0

    return new Promise((resolve, reject) => {
      let url = '/workflows'
      let params = new URLSearchParams({ limit: limit, offset: offset })

      api.get(url + '?' + params)
        .then(response => {
          // addOrUpdateInList(response.data, state.workflows, 'workflow', commit)
          commit('SET_WORKFLOWS', response.data)

          if (response.data.length == limit) {
            offset += limit
            limit = recursiveCallIterativeLimit

            recursiveCall(url, 'workflow', limit, offset, state.workflows, commit, 1).then(() => {
              store.commit('app/REMOVE_LOADER')
              resolve(true)
            })
          } else {
            store.commit('app/REMOVE_LOADER')
            resolve(true)
          }

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  getWorkflow ({ commit, state }, workflowId) {
    store.commit('app/ADD_LOADER')

    return new Promise((resolve, reject) => {
      api.get('/workflows/' + workflowId)
        .then(response => {
          addOrUpdateInList(response.data, state.workflows, 'workflow', commit)

          store.commit('app/REMOVE_LOADER')
          resolve(response.data)

        })
        .catch(err => {
          console.log('Error', err)
          reject(err)
        })
    })
  },
  async addWorkflow ({ commit, state }, workflow) {
    return await cleanEntityToSend(workflow, state.params)
      .then(async clonedEntityToSend => {
        return new Promise((resolve, reject) => {
          api.post('/workflows', clonedEntityToSend)
            .then(response => {
              commit('ADD_WORKFLOW', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The workflow has been ', { crud: i18n.t('created') }),
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  async updateWorkflow ({ commit, state }, workflow) {
    return await cleanEntityToSend(workflow, state.params)
      .then(async clonedEntityToSend => {

        return new Promise((resolve, reject) => {
          api.put('/workflows/' + workflow.id, clonedEntityToSend)
            .then(response => {
              commit('UPDATE_WORKFLOW', response.data)

              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: i18n.t('The workflow has been ', { crud: i18n.t('edited') }),
                  variant: 'success'
                },
              })

              resolve(response)
              // resolve(JSON.parse(JSON.stringify(response)))
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
        })

      })
      .catch(error => {
        console.log('Error', error)
        reject(error)
      })
  },
  removeWorkflow ({ commit }, workflow) {
    return new Promise((resolve, reject) => {
      api.delete('/workflows/' + workflow.id)
        .then(response => {
          commit('REMOVE_WORKFLOW', workflow)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: capitalize(i18n.t('success')),
              icon: 'check',
              text: i18n.t('The workflow has been ', { crud: i18n.t('removed') }),
              variant: 'success'
            },
          })

          resolve(response)
        })
        .catch(error => {
          console.log('Error', error)
          reject(error)
        })
    })
  },

}