import moment from 'moment'

export default {
  bankOperations: [],
  emptyBankOperation: {
    operationDate: (new Date()).toISOString().slice(0, 10),
    bankTransactionDate: (new Date()).toISOString().slice(0, 10),
  },
  params: {
    entity: [
      'bankAccount',
      'bankOperationConnection',
      // 'company',
      'companies',
      'paymentMethod',
      'incomingInvoices',
      'outgoingInvoices',
      'salary',
      'socialSecurityContribution'
    ],
    collection: []
  }
}