export default {
  SET_PAYMENTMETHODS (state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },

  SET_AUTOCOMPLETEPAYMENTMETHODS (state, autocompletePaymentMethods) {
    state.autocompletePaymentMethods = autocompletePaymentMethods
  },
  // Payload : paymentMethod
  ADD_AUTOCOMPLETEPAYMENTMETHOD (state, payload) {
    state.autocompletePaymentMethods.push(payload)
  },
  // Payload : paymentMethod
  UPDATE_AUTOCOMPLETEPAYMENTMETHOD (state, payload) {
    console.log(payload)
    let index = state.autocompletePaymentMethods.findIndex(i => i.id === payload.paymentMethod.id)
    state.autocompletePaymentMethods[index].name = payload.paymentMethod.name
  },
}