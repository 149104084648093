import api from '@/libs/axios'

export default {
  getPaymentTerms ({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.paymentTerms.length == 0) {
        api.get('/paymentTerms')
          .then(response => {
            commit('SET_PAYMENTTERMS', response.data)
            resolve(response)
          })
          .catch(err => {
            console.log('Error', err)
            reject(err)
          })
      } else {
        resolve(true)
      }

    })
  },

}