let expirationDate = new Date()
expirationDate.setDate(expirationDate.getDate() + 30)

export default {
  offers: [],
  emptyOffer: {
    billingDate: (new Date()).toISOString().slice(0, 10),
    expirationDate: expirationDate.toISOString().slice(0, 10),
    paymentMethod: 1,
    paymentTerm: 3,
    products: [],
    isDiscountPercent: false,
    preTaxAmount: 0,
    vat: 0,
    totalAmount: 0,
  },
  params: {
    entity: [
      'article',
      'billingAddress',
      'childs',
      'customerCompany',
      'customerIndividual',
      'deliveryAddress',
      'paymentTerm',
      'paymentMethod',
      'phase',
      'vatRate',
      'workflow',
      'signedDocument',
      'toInvoice',
      'outgoingInvoice',
      'quotes'
    ],
    collection: [
      'products'
    ]
  }
}