export default {
  SET_BANKTRANSACTIONS (state, bankTransactions) {
    state.bankTransactions = bankTransactions
  },
  ADD_BANKTRANSACTION (state, bankTransaction) {
    state.bankTransactions.push(bankTransaction)
  },
  UPDATE_BANKTRANSACTION(state, bankTransaction) {
    let index = state.bankTransactions.findIndex(i => i.id === bankTransaction.id)
    state.bankTransactions.splice(index, 1, bankTransaction)
  },
  REMOVE_BANKTRANSACTION (state, bankTransaction) {
    state.bankTransactions = state.bankTransactions.filter(item => item.id != bankTransaction.id)

    // let index = state.bankTransactions.findIndex(i => i.id === bankTransaction.id)
    // state.bankTransactions.splice(index, 1)
  },
  SET_BANKTRANSACTIONSINDICATOR (state, bankTransactionsIndicator) {
    state.bankTransactionsIndicator = bankTransactionsIndicator
  },
  SET_BANKTRANSACTIONSCONTEXT (state, bankTransactionsContext) {
    state.bankTransactionsContext = bankTransactionsContext
  },
  UPDATE_BANKTRANSACTIONSCONTEXTTOTALRESULT (state, isAddBankTransaction) {
    if (isAddBankTransaction) {
      state.bankTransactionsContext.total_results++
    } else {
      state.bankTransactionsContext.total_results--
    }
  },
}