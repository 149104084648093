export default {
  bankTransactions: [],
  bankTransactionsIndicator: {},
  emptyBankTransaction: {
    files: [],
    priority: 'Normal',
    type: 'Issue',
    status: 'New'
  },
  params: {
    entity: [
      'attachment',
      'attachmentCategory',
      'linkedTransaction',
      'payments',
      'paymentMethod',
    ],
    collection: []
  },
  bankTransactionsContext: {}
}